import { useState, useEffect } from 'react';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { Divider, List, Skeleton, Modal, ConfigProvider, Table } from "antd";
import InfiniteScroll from 'react-infinite-scroll-component';
import logo from '../assets/images/logo.png';
import discord from '../assets/images/discord2.png';
import tele from '../assets/images/tele2.png';
import twi from '../assets/images/twi2.png';
import med from '../assets/images/med2.png';
import gitbook from '../assets/images/GitBook.png';
import btc_tile from '../assets/images/2048.png';
import pillblue from '../assets/images/pillblue.png';
import pillred from '../assets/images/pillred.png';
import lb from '../assets/images/lb_sonic.png';
import gold from '../assets/images/gold.png';
import silver from '../assets/images/silver.png';
import bronze from '../assets/images/bronze.png';
import fourth from '../assets/images/fourth.png';
import points from '../assets/images/points.png';
import * as buffer from "buffer";
import { TOKEN_2022_PROGRAM_ID, getTokenMetadata } from '@solana/spl-token';
import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { PublicKey } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
const { useAnchorContextFrontier } = require('../shared/provider/AnchorProvider_frontier.tsx');




function LeaderBoard() {
    const navigate = useNavigate();
    const [showSoon, setShowSoon] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [playerID, setPlayerID] = useState(0);
    const [nftObjects, setNftObjects] = useState([]);
    const [leaderboardObjects, setLeaderboardObjects] = useState([])
    const [team, setTeam] = useState(0);
    const [over, setOver] = useState(false);
    const [btcTiles, setBtcTiles] = useState('Loading');
    const { publicKey, sendTransaction, signTransaction, wallet } = useWallet();
    const { season, fomoloveSonicProgram: program, fetchWinnerState } = useAnchorContextFrontier();
    const [userState, setUserState] = useState(null);
    const [isBelongsToTeam, setIsBelongsToTeam] = useState(false);
    const [userTeam, setUserTeam] = useState('')
    const [nfts, setNfts] = useState([]);
    const { connection } = useConnection();
    const [btcWinner, setBtcWinner] = useState([])
    const [seasonId, setSeasonId] = useState(8);
    const [lbSeason1, setLbSeason1] = useState([]);




    // const [topTile, setTopTile] = useState("");
    // const { error, loading, balance } = useAccountBalance();

    let topTile = 2;

    //console.log()


    const handleSoon = () => {
        setShowSoon(false);
    }



    const fetchNFTS = async () => {
        if (!season || !publicKey) {
            return;
        }

        // const testKey = new PublicKey('GZ4KfHC5MULiv99BTZdWL9Rg3XN8APoerqWDRqoQmQ3F');


        const token2022Accounts =
            await connection.getParsedTokenAccountsByOwner(publicKey, {
            // await connection.getParsedTokenAccountsByOwner(testKey, {
                programId: TOKEN_2022_PROGRAM_ID,
            });

        const _nfts = [];

        try {
            for (let nft of token2022Accounts.value) {
                const publibKey = new PublicKey(nft.account.data.parsed.info.mint);
                const nftMetatadata = await getTokenMetadata(
                    connection,
                    publibKey,
                    'confirmed'
                );

                if (
                    nftMetatadata &&
                    program?.configPDA &&
                    nftMetatadata.updateAuthority?.equals(program?.configPDA)
                ) {
                    _nfts.push(nftMetatadata);
                }
            }

            // console.log('_nfts',_nfts)

            if (!_nfts) {
                return;
            }

            const nft_season = _nfts?.filter((object) => {
                if (object.additionalMetadata[0][1] === season.seasonId.toString()
                ) {
                    return true;
                }
                return false;
            })

            // console.log('nft_season',nft_season);

            const nft_object = await Promise.all(nft_season?.map(async (object) => {
                const isEnded = object.additionalMetadata.filter(
                    ([key, value]) =>
                        key == 'game_over' && value == 'true'
                ).length;

                const logo = object.uri?.split('/').pop().split('.')[0];
                let index = logo.indexOf('me');
                const team = index === 0 ? 'm' : 'ch';
                let tile_raw = index === 0 ? (logo.length>4?Number(logo.slice(4)):Number(logo.slice(2))) : Number(logo.slice(5));
                tile_raw = tile_raw>11 ? 11 : tile_raw;
                const display = team + `${tile_raw}`;
                const tile_pow = Math.pow(2, tile_raw);

                return {
                    isEnded: isEnded,
                    team: team,
                    tile: tile_pow,
                    display: display,
                    mint: object.mint,
                }
            })
            .sort((a, b) => {
                return (b.tile - a.tile)
            })
            );

            setNfts(nft_object);
            console.log('nft_object',nft_object)

            let count = 0;

            // console.log('_nfts', _nfts);


            const nft_btc = _nfts?.map((object) => {
                // console.log('object', object.uri);
                const logo = object.uri?.split('/').pop().split('.')[0];

                let index = logo.indexOf('me');
                const team = index === 0 ? 'm' : 'ch';
                let tile = index === 0 ? Number(logo.slice(2)) : Number(logo.slice(5));

                // console.log('display', display);
                tile = Math.pow(2, tile);
                if (tile >= 2048) {
                    count += 1;
                }else if(6<=Number(object.additionalMetadata[1][0])<=14 && tile==2 && object.additionalMetadata[1][1]=='true'){
                    count+=1
                }
            })

            setBtcTiles(count)

        } catch (e) {
            console.error('Fetch NFTs failed', e);
        }
    }

    const detectUser = async () => {
        // console.log('hi user')
        if (!publicKey || !program) return;

        try {
            const userAccount = await program.getUserState(publicKey);
            // console.log('userAccount',userAccount)
            if (
                !!userAccount
            ) {
                setIsBelongsToTeam(true);
            }
            userAccount && setUserState(userAccount);
            if (userAccount) {
                // console.log('userAccount', userAccount)
                // setUserTeam(userAccount.team)
                if (userAccount.team.chainTeam) {
                    console.log('yes chainteam');
                    setUserTeam('ChainTeam')
                } else {
                    console.log('yes memeteam');
                    setUserTeam('MemeTeam');
                }
                fetchNFTS();
            }

            // return userAccount;
        } catch (error) {
            console.log('🚀 ~ detectUser ~ error:', error);
        }

    };

    const detectLeaderboard = () => {
        // console.log('season',season)

        if (!season) return;
        setSeasonId(season.seasonId)



        const top_games = season?.leaderboard?.topGames
        // console.log('top_games',top_games)
        if (!top_games) {
            return;
        }
        const top_nfts = top_games.map((object, index) => {
            const team = object.team.chainTeam ? 'chain' : 'meme';
            let toptile = object.topTile;
            toptile = toptile>2048 ? 2048 : toptile;

            let tile_log = Math.log(toptile) / Math.log(2);
            tile_log = tile_log > 12 ? 11 : tile_log;
            // console.log('tile_log',tile_log);
            // console.log('toptile',toptile)
            const display_tile = team === 'chain' ? `cc${tile_log}` : `mm${tile_log}`;
            const pill = team === 'chain' ? pillblue : pillred
            const playerAddress = object.player.toString();

            const address = '(' + playerAddress.slice(0, 4) + '...' + playerAddress.slice(-5, -1) + ')'

            // console.log('player_address',object.player.toString());
            // console.log(index)
            return {
                idx: index + 1,
                team: team,
                player_address: address,
                score: object.score,
                toptile: toptile,
                display_tile: display_tile,
                pill: pill,
            }
        })
        // .sort((a, b) => {
        //     if (a.toptile == b.toptile) {
        //         return b.score - a.score
        //     } else {
        //         return b.toptile - a.toptile
        //     }
        // })
        // const res = top_nfts.map((object, index) => {
        //     return {
        //         idx: index + 1,
        //         team: object.team,
        //         player_address: object.player_address,
        //         score: object.score,
        //         toptile: object.toptile,
        //         display_tile: object.display_tile,
        //         pill: object.pill,
        //     }
        // })
        setLeaderboardObjects(top_nfts);
    }

    const detectBtcWinner = async () => {
        const winnerData = await fetchWinnerState();
        // console.log('winnerData', winnerData?.leaderboard);
        if (!winnerData) {
            return;
        }
        const winner_list = winnerData.leaderboard;
        // console.log('winner_list',winner_list);
        const winnerNft = winner_list.map((object, index) => {
            const player = object.user.toString();
            const address = '(' + player.slice(0, 4) + '...' + player.slice(-5, -1) + ')'
            const toptile = object.topTile;
            const tile_log = Math.log(toptile) / Math.log(2);
            const display_tile = team === 'chain' ? `cc${tile_log}` : `mm${tile_log}`;


            return {
                player_address: address,
                seasonId: object.seasonId.toString(),
                score: object.score,
                display_tile: display_tile,
                toptile: toptile,
            }
        }).sort((a, b) => {
            return b.score - a.score;
        }).map((object, index) => {
            return {
                idx: index + 1,
                player_address: object.player_address,
                seasonId: object.seasonId,
                score: object.score,
                display_tile: object.display_tile,
                toptile: object.toptile,
            }
        })
        // console.log('winnerNft',winnerNft)
        setBtcWinner(winnerNft);
    }
    const questWinner = [
        {
            // idx: 1,
            display_tile: gold,
            player_address: '米',
            points: points,
            score: 103100,
        },
        {
            // idx: 2,
            display_tile: gold,
            player_address: 'Dan5718',
            points: points,
            score: 103100,
        },
        {
            // idx: 3,
            display_tile: silver,
            player_address: 'hurdudurdu',
            points: points,
            score: 101600,
        },
        {
            // idx: 4,
            display_tile: silver,
            player_address: 'saptahari',
            points: points,
            score: 101600,
        },
        {
            // idx: 4,
            display_tile: bronze,
            player_address: 'KriptoZang',
            points: points,
            score: 101500,
        },
        {
            idx: 5,
            // display_tile: bronze,
            player_address: 'sandeepsaka',
            points: points,
            score: 101100,
        },
        // {
        //     idx: 5,
        //     // display_tile: fourth,
        //     player_address: 'Theprotege',
        //     points: points,
        //     score: 65100,
        // },

    ];

    const detectInfo = async () => {
        if (!program) return;
        console.log('hi user')



        try {
            const seasonState = await program.getSeasonState(31);
            if (
                !!seasonState
            ) {
                console.log('seasonState', seasonState)

                const top_games = seasonState.leaderboard.topGames
                const res = top_games.map((object, index) => {
                    const team = object.team.chainTeam ? 'chain' : 'meme';
                    let toptile = object.topTile;
                    toptile = toptile>2048? 2048:toptile
                    let tile_log = Math.log(toptile) / Math.log(2);
                    tile_log = tile_log > 11 ? 11 : tile_log;
                    const display_tile = team === 'chain' ? `cc${tile_log}` : `mm${tile_log}`;
                    const pill = team === 'chain' ? pillblue : pillred
                    const playerAddress = object.player.toString();

                    const address = '(' + playerAddress.slice(0, 4) + '...' + playerAddress.slice(-5, -1) + ')'

                    return {
                        idx: index + 1,
                        team: team,
                        player_address: playerAddress,
                        score: object.score,
                        toptile: toptile,
                        display_tile: display_tile,
                        pill: pill,
                    }
                })
                console.log('res', res)
                setLbSeason1(res);
            }

            // return userAccount;
        } catch (error) {
            console.log('🚀 ~ detectUser ~ error:', error);
        }

    }
    const frontier = () => {
        const address = [
            {player_address: 'EDJmmatSUnk7NECgFxhHrswDgCr9dt5LdMiey36DmZ6M'},
            {player_address: 'G73GjXvB6YkqqJ9zD5ZGtcube2jQnCWjnejqnRhEW5TA'},
            {player_address: '9z5Q5quJUzVZmTbyaZh4XwRj6sj84Um1qdB3a3qUaQXn'},
            {player_address: '3EjwRJDDfiBDnwXuPnKYHhdrSAGcBsJs8cwrctYjRbL4'},
            {player_address: 'E2wNiAjkJCurJRaxwqDQiKUJzwmkhVwHWZoLQKjvy6mF'},
            {player_address: 'Bxi23tW9umxvM2pHQ6gzLJmr3EbapdqUm6hbcBKjAeNZ'},
            {player_address: 'FMfP1eWcTkp7pu9ZDbaTyJ1B2MBJicLoKzxdAWTPrKUZ'},
            {player_address: 'GtFj91C5QcxiyqPTijkebyJ74rPj4HqhVzLCq21bkNcw'},
            {player_address: '5t7FjRZRsfgdiMSEn5DEh56W2LeawooSaJSDBkFWSrUN'},
            {player_address: 'G8Q24nTv6Ld8C1nuetfmT9QPPfVLxhy37LBfBJS3aEXW'},
            {player_address: 'ACmam6LFqmGbioWZ2pqsbhJetKgUMoxmkavEbtwLy94m'},
            {player_address: '3NCLk2dh7uFjuahE4JkH3hHbKBUmpubA7bdUpUgpSqVY'},
            {player_address: '2K4GMz5g7dXUevTR5ZQjcdTCoRqNK6yFNnmhRQUQfRzD'},
            {player_address: '5xRjtXhQ7TuAv8A5KcJtXpgL4vr5xAZ5Y3qJMGZxV4Rn'},
            {player_address: 'H1JcgzoXk4RNqHsfkMnQNKuL8j5ZJktNGcSuEFuAwUJv'},
        ]
        setLbSeason1(address);

    }

    useEffect(() => {
        detectLeaderboard();
        detectUser();
        // detectBtcWinner();
        // detectInfo();
        // frontier();
    }, [publicKey])



    const handleLogo = () => {
        navigate('/');
    }

    const handleNaviPurchase = () => {
        navigate('/');
    }

    const handleNaviPlay = () => {
        document.querySelector('.nav_leaderboard').classList.remove('selected');
        document.querySelector('.nav_play').classList.add('selected');
        navigate('/testnet-play');
    }

    const handleNaviLeaderboard = () => {
        window.location.reload();
    }

    const handleLoadGame = (game, e) => {
        if (game.isEnded) {
            // console.log('game',game);
            setOver(true)
            return
        } else {
            navigate(`/testnet-play?id=${game.mint}`);
            window.scrollTo({
                top: 0,
            });
        }
    }

    const handleDis = () => {
        window.open('https://discord.com/invite/HP6cBgaP9w', '_blank');
    }

    const handleTel = () => {
        window.open('https://t.me/fomoneyofficial', '_blank');
    }

    const handleTwi = () => {
        window.open('https://twitter.com/Fomoney2048', '_blank');
    }

    const handleMed = () => {
        window.open('https://medium.com/@Fomoney2048', '_blank');
    }

    const handleGit = () => {
        window.open('https://fomoney.gitbook.io/fomoney-litepaper', '_blank');
    }

    const handleOver = () => {
        setOver(false);
    }

    const handleFirstGame = () => {
        navigate('/testnet-play');
    }

    const handleShowSoon = () => {
        setShowSoon(true);
    }


    const leaderList = () => {

        if (leaderboardObjects.length != 0) {
            return (<div
                id="scrollableDiv"
                className='leaderboard_list'
                style={{
                    height: 480,
                    // height: 720,
                    width: '75%',
                    // width: '85%',
                    overflow: 'auto',
                    padding: '10px 20px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                    display: 'block',
                    margin: '0 auto',
                    marginTop: '20px',
                    fontFamily: 'futura',
                    fontWeight: 'bold',
                }}
            >
                <InfiniteScroll
                    // leaderboardObjects
                    dataLength={leaderboardObjects.length}
                    // lbSeason1
                    // next={loadMoreData}
                    hasMore={leaderboardObjects.length < 50}
                    loader={
                        <Skeleton
                            // avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    endMessage={<Divider plain></Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        className='leaderboard_all'
                        dataSource={leaderboardObjects}
                        renderItem={(item) => (
                            <List.Item  >
                                <div className='leaderboard_display'>
                                    <div className='leaderboard_index'>{item.idx}</div>
                                    <img src={item.pill} className='leaderboard_team_img' />
                                    <div className={item.display_tile}></div>
                                    <div className='leaderboard_score_status'>
                                        <div className='leaderboard_status'>
                                            <div>Tile:</div>
                                            <div className='leaderboard_my_score_num'>{item.toptile}</div>
                                        </div>
                                        <div className='leaderboard_score'>
                                            <div>Score: </div>
                                            <div className='leaderboard_my_score_num'>{item.score}</div>
                                        </div>
                                        <div className='leaderboard_address'>{item.player_address}</div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>)
        } else {
            return (
                <div>
                    <img className='lb' src={lb} />
                    <div className='lb_soon'>Loading...</div>
                </div>
            )
        }
    }

    const gameList = () => {
        // console.log(nftObjects);
        if (nfts.length !== 0) {
            return (<div
                id="scrollableDiv"
                className='my_game_list'
                style={{
                    height: 280,
                    width: 210,
                    overflow: 'auto',
                    padding: '10px 20px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                    display: 'block',
                    margin: '0 auto',
                    marginTop: '20px',
                    fontFamily: 'futura',
                    fontWeight: 'bold',
                }}
            >
                <InfiniteScroll
                    dataLength={nfts.length}
                    // next={loadMoreData}
                    hasMore={nfts.length < 50}
                    loader={
                        <Skeleton
                            // avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    endMessage={<Divider plain></Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={nfts}
                        renderItem={(item) => (
                            <List.Item className={item.display} onClick={(e) => { handleLoadGame(item, e) }}>
                                <div className='leaderboard_my_game_display'>
                                    <div className='leaderboard_my_game_action'>{item.isEnded ? 'Ended' : 'Reload'}</div>
                                    <div className='leaderboard_toptile'>
                                        <div className='toptile_text'>Top Tile:&nbsp;{item.tile}</div>
                                        {/* <div className='leaderboard_my_score_num'>{item.tile}</div> */}
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>)
        } else {
            return (
                <button className="fist_game_bt" onClick={handleFirstGame}>Play</button>
            )
        }
    }

    const winnerList = () => {
        // console.log(nftObjects);
        // btcWinner
        if (questWinner.length !== 0) {
            return (<div
                id="scrollableDiv"
                className='leaderboard_list'
                style={{
                    height: 480,
                    // width: 450,
                    width: '70%',
                    overflow: 'auto',
                    padding: '10px 20px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                    display: 'block',
                    margin: '0 auto',
                    marginTop: '20px',
                    fontFamily: 'futura',
                    fontWeight: 'bold',
                }}
            >
                <InfiniteScroll
                    dataLength={questWinner.length}
                    // next={loadMoreData}
                    hasMore={questWinner.length < 50}
                    loader={
                        <Skeleton
                            // avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        className='leaderboard_all'
                        dataSource={questWinner}
                        renderItem={(item) => (
                            <List.Item  >
                                <div className='quest_display'>
                                    {item.idx?(<div className='quest_idx'>
                                        {item.idx}
                                    </div>):null}
                                    {item.display_tile?(<img className='medals' src={item.display_tile}></img>):null}
                                    <div className='leaderboard_quest_bot'>
                                        <div className='leaderboard_name'>{item.player_address}</div>
                                        <img className='points' src={item.points}></img>
                                        <div className='leaderboard_score'>
                                            <div>Points: </div>
                                            <div className='leaderboard_my_score_num'>{item.score}</div>
                                        </div>
                                        {/* <div className='leaderboard_score'>
                                            <div>Tile: </div>
                                            <div className='leaderboard_my_score_num'>{item.toptile}</div>
                                        </div>
                                        <div className='leaderboard_score'>
                                            <div>Season: </div>
                                            <div className='leaderboard_my_score_num'>{item.seasonId}</div>
                                        </div> */}
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>)
        } else {
            return (
                <div>
                    <img className='lb' src={lb} />
                    <div className='lb_soon'>Loading...</div>
                </div>
            )
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: () => <a>Delete</a>,
        },
    ];


    return (

        <div className='leaderboard_container'>
            <body className='leaderboard_body'>
                <div className="header">
                    <div className="header_logo">
                        <img src={logo} alt='Logomark' onClick={handleLogo} className='logomark' />
                    </div>

                    <div className="navigation_bt">
                        <div className="nav nav_purchase" onClick={handleNaviPurchase}>HOME</div>
                        <div className="nav nav_play" onClick={handleNaviPlay}>PLAY</div>
                        <div className="nav nav_leaderboard selected" onClick={handleNaviLeaderboard}>LEADERBOARD</div>
                        <WalletModalProvider>
                            <WalletMultiButton />
                        </WalletModalProvider>
                    </div>
                </div>
                <div className='leaderboard_content'>
                    <div className='leaderboard_left'>
                        <div className='leaderboard_title'>MY BITCOIN NFT</div>
                        <img className='btc_tile' src={btc_tile} />
                        <div className='btc_tile_num'>{btcTiles}</div>
                        <div className='leaderboard_title_2'>MY GAME</div>
                        {/* <img className='lb' src={lb} /> */}
                        <div>{gameList()}</div>
                        {/* <div className='lb_soon'>Coming soon...</div> */}
                    </div>
                    {/* <div className='leaderboard_middle'>
                        <div className='leaderboard_title'>LEADERBOARD</div>
                        <div className='leaderboard_title_3'>SEASON&nbsp;{seasonId} &nbsp; &nbsp; TOP 10</div>
                        <div>{leaderList()}</div>
                        <div className='leaderboard_last_line'>Join <a className='yellow' href='https://t.me/fomoneyofficial'>telegram group</a> to check daily leaderboard announcement.</div>
                    </div> */}
                    <div className='leaderboard_right'>
                        <div className='leaderboard_title'>QUEST BOT LEADERBOARD</div>
                        <div className='leaderboard_title_3'>Total Balance: 60,456,400&nbsp;&nbsp; Total User: 5,260</div>
                        <div>{winnerList()}</div>
                        <div className='leaderboard_last_line'>Join <a className='yellow' href='https://t.me/fomoney_io_bot'>quest bot</a> to earn 400k rings.</div>
                    </div>
                </div>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 100, }}
                            open={over}
                            onOk={handleOver}
                            onCancel={handleOver}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">This game is over</div>
                        </Modal>
                    </div>
                </ConfigProvider>


                <div className="media">
                    {/* <img src={mail} alt='mail' className='mail' onClick={handleMail} /> */}
                    <img src={twi} alt='twi' className='twi' onClick={handleTwi} />
                    <img src={gitbook} alt='gitbook' className='gitbook' onClick={handleGit} />
                    <img src={tele} alt='tele' className='tele' onClick={handleTel} />
                    <img src={discord} alt='discord' className='discord' onClick={handleDis} />
                    <img src={med} alt='med' className='med' onClick={handleMed} />
                </div>
                <div className='copyright'>© 2024 FoMoney.io</div>
                <div className="bottom"></div>
            </body>
        </div>
    )
}

export default LeaderBoard;
