'use client';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import AnchorContextProvider from './AnchorProvider_frontier.tsx'
import React, { useMemo } from 'react';

import {
    ConnectionProvider,
    WalletProvider,
} from '@solana/wallet-adapter-react';
import '@solana/wallet-adapter-react-ui/styles.css';

const WalletContextProvider = ({
    children,
}) => {
    // const queryClient = new QueryClient();
    const network = WalletAdapterNetwork.Devnet;
    // const rpcEndpoint = 'https://devnet.sonic.game';
    const rpcEndpoint = 'https://api.testnet.sonic.game/';

    const endpoint = useMemo(() => rpcEndpoint, [network]);
    const wallets = useMemo(
        () => [
            //new NightlyWalletAdapter(),
            // new UnsafeBurnerWalletAdapter(),
            // new PhantomWalletAdapter(),
        ],
        [network]
    );

    return (
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                    <AnchorContextProvider>
                        {children}
                    </AnchorContextProvider>
                </WalletProvider>
            </ConnectionProvider>
    );
};

export default WalletContextProvider;
